import { SelectItem } from 'primeng/api';
import { EligibilityPurposeType } from '../enums';

export class EligibilityPurposeTypeLookup {
    static get(): SelectItem[] {
        return [
            { label: 'Benefit', value: EligibilityPurposeType.Benefit },
            { label: 'Discovery', value: EligibilityPurposeType.Discovery },
            { label: 'Validation', value: EligibilityPurposeType.Validation }];
    }

    static getAll(): SelectItem[] {
        return [
            { label: 'Auth Requirement', value: EligibilityPurposeType.AuthRequirement },
            { label: 'Benefit', value: EligibilityPurposeType.Benefit },
            { label: 'Discovery', value: EligibilityPurposeType.Discovery },
            { label: 'Validation', value: EligibilityPurposeType.Validation }];
    }

    static getTitleById(id) {
        return this.getAll().find(f => f.value === id)?.label || '';
    }

    static getTitleByIds(ids) {
        let title = '';
        let count = 0;
        let eligibilityPurposeTypesEnum = this.getAll();
        ids.forEach(id => {
            title+= (count > 0 ? ' | ' : '') + eligibilityPurposeTypesEnum.find(f => f.value === id)?.label || '';
            count++;
        });
        return title;
    }
}
