import { DischargeStatus } from '../enums/discharge-status';
import { SelectItem } from 'primeng/api';

export class DischargeStatusLookup {
    static getDischargeStatusLookup(): SelectItem[] {
        return [
            { label: 'Regular', value: DischargeStatus.Regular },
            { label: 'Death', value: DischargeStatus.Death },
            { label: 'Doctor Permission', value: DischargeStatus.DoctorPermission },
            { label: 'Against Medical Advice', value: DischargeStatus.AgainstMedicalAdvice },
            { label: 'Irregular', value: DischargeStatus.Irregular },
            { label: 'Transfer Out', value: DischargeStatus.TransferOut },
            { label: 'Cancel Admission', value: DischargeStatus.CancelAdmission },
        ];
    }


}
