import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class OrderApiConfiguration {
    server: string;

    constructor() {
        this.server = environment.order;
    }

    order =
        {
            url: 'Order',
            editOrderDoctor: 'Order/EditOrderDoctor',
            checkBalance: 'Order/Item/WarehouseBalance',
            latestOrderDate: 'Order/LatestOrderDate',
            publishRadilogyHistory: 'Order/RadilogyHistory',
            approvePendingAreas: 'Order/ApprovePendingAreas',
            checkBatchBalance: 'Order/Item/Balance/Batch',
            publishMedicationHistory: 'Order/MedicationHistory',
            postConsultationService: 'Order/ConsultationService',
            rollBackPendingAreas: 'Order/RollBackPendingAreas',
        };

    transaction =
        {
            url: 'StatementTrans',
        };

    voucher =
        {
            url: 'VoucherType',
        };

    profitMargin =
        {
            url: 'PriceListProfitMargin',
        };

    profitMarginDetail =
        {
            url: 'PriceListProfitMarginDetails',
        };

    unit =
        {
            url: 'Unit',
            byGroupId: 'Unit/ByGroupId',
            byGroupIds: 'Unit/ByGroupIds',
        };

    orderDetail = {
        url: 'OrderDetail',
        cancelOrderDetail: 'OrderDetail/CancelOrderDetail',
        cancelMultiOrder: 'OrderDetail/CancelMultiOrder',
        orderIndex: 'OrderDetail/OrderIndex',
        orderActionElement: 'OrderDetail/OrderActionElement',
        execludeOrderAction: 'OrderDetail/ExecludeOrderAction',
        orderByCategory: 'OrderDetail/OrderByCategory',
        receivedOrderDetail: 'OrderDetail/ReceivedOrderDetail',
        completeOrderDetail: 'OrderDetail/CompleteOrderDetail',
        changeOrderStatus: 'OrderDetail/ChangeOrderStatus',
        multiOrderActionElement: 'OrderDetail/MultiOrderActionElement',
        orderPriority: 'OrderDetail/OrderPriority',
        changeOrderReceiveStatus: 'OrderDetail/ChangeOrderReceiveStatus',
        receivedOrderLog: 'OrderDetail/ReceivedOrderLog',
        doctorNote: 'OrderDetail/DoctorNote',
        pendingAreaOrderDetail: 'OrderDetail/PendingAreaOrderDetail',
        cancelPendingAreaDraftOrder: 'OrderDetail/CancelPendingAreaDraftOrder',
        cancelPendingAreaDraftOrders: 'OrderDetail/CancelPendingAreaDraftOrders',
        pendingAreaOrderDetailById: 'OrderDetail/PendingAreaOrderDetailById',
        updateOrderQuantity: 'OrderDetail/UpdateOrderQuentity',
    };


    insurance = {
        url: 'CoverageAproval',
    };

    funds =
        {
            url: 'Fund',
            all: 'Fund/All',
            active: 'Fund/Active',
            getLookUp: 'Fund/GetLookUp',
            getCashierFundsUrl: 'Fund/CashierFunds',
            mainFund: 'Fund/MainFund',
            isMainFund: 'Fund/IsMainFund',
            loggedInFund: 'Fund/LoggedInFund',
        };

    cahierfunds =
        {
            url: 'CashierFund',
            getFundsForPermittedUser: 'CashierFund/FundsForPermittedUser',
            getCashierStaffId: 'CashierFund/CashierStaffId',
        };

    cashierTrans =
        {
            url: 'CashierPrivilege',
            byStaffId: 'CashierPrivilege/ByStaffId',
            byLoggedUser: 'CashierPrivilege/ByLoggedUser',
        };

    patientStatement = {
        url: 'PatientStatement',
        getByFillter: 'PatientStatement/GetByFillter',
        reOpen: 'PatientStatement/ReOpen',
        financeRelease: 'PatientStatement/FinanceRelease',
        issueBill: 'PatientStatement/IssueBill',
        inPatientPatientTotalAmount: 'PatientStatement/InPatientPatientTotalAmount',
        haltStatement: 'PatientStatement/HaltStatement',
        openHaltStatement: 'PatientStatement/OpenHaltStatement',
        validateStatement: 'PatientStatement/Validate',
        isOrderAttachedToOpenStatements: 'PatientStatement/OrderAttachedToOpenStatements',
        canDischargePatient: 'PatientStatement/CanDischargePatient',
        canCancelPatientAdmission: 'PatientStatement/CanCancelPatientAdmission',
        isOrderActionAttachedToOpenStatements: 'PatientStatement/OrderActionAttachedToOpenStatements',
        blockStatement: 'PatientStatement/BlockStatement',
        unBlockStatement: 'PatientStatement/UnBlockStatement',
        creditInvoiceSearch: 'PatientStatement/CreditInvoicesSearch',
        creditInvoicesSearchForAddInvoice: 'PatientStatement/CreditInvoicesSearchForAddInvoice',
        canTransfePatient: 'PatientStatement/CanTransferPatient',
        remark: 'PatientStatement/Remarks',
        getDealingType: 'PatientStatement/DealingType',
        getCloseDate: 'PatientStatement/CloseDate',
        setCloseDate: 'PatientStatement/SetCloseDate',
        getDischargeDate: 'PatientStatement/GetDischargeDate',
        isStatementOpenOrHalted: 'PatientStatement/IsStatementOpenOrHalted',
        encounterInfo: 'PatientStatement/EncounterInfo',
        validateForChangeEncounter: 'PatientStatement/ValidateForChangeEncounter',
        cashStatementInvoiceNet: 'PatientStatement/CashStatementInvoiceNet',
        creditStatmentInvoice: 'PatientStatement/PatientCreditStatements',
        openHaltOutpatientStatement: 'PatientStatement/OpenHaltOutpatientStatement',
        haltOutpatientStatement: 'PatientStatement/HaltOutpatientStatement',
        validateStatementsForApproval: 'PatientStatement/ValidateStatementsForApproval',
        haltOutPatientStatementById: 'PatientStatement/HaltOutPatientStatementById',
        validateStatementsPendingArea: 'PatientStatement/ValidateStatementsPendingArea',
    };

    claim = {
        url: 'Claim',
        claimByPatientUrl: 'Claim/ByPatient',
        changeClaimStatus: 'Claim/ChangeClaimStatus',
        claimAmount: 'Claim/ClaimAmount',
        existClaimInvoice: 'Claim/ExistClaimInvoice',
        byId: 'Claim/ById',
    }

    claimDetail = {
        url: 'ClaimDetail',
        getEClaimStatusUrl: 'ClaimDetail/EClaimStatus',
        cancelEClaimUrl: 'ClaimDetail/CancelEClaim',
        exortToExcel: 'ClaimDetail/ClaimDetailCsv',
        settlementLinkedInvoices: 'ClaimDetail/SettlementLinkedInvoices',
        settlementNoneLinkedInvoices: 'ClaimDetail/SettlementNoneLinkedInvoices',
        getNoneLinkedInvoicesForExcel: 'ClaimDetail/GetNoneLinkedInvoicesForExcel',
        importLinkInvoices: 'ClaimDetail/ImportLinkInvoices'
    }

    billServices = {
        url: 'BillService',
        getByFillter: 'BillService/GetByFillter',
        getDiscountableElement: 'BillService/DiscountableElement',
        discount: 'BillService/Discount',
        invoiceIndex: 'BillService/InvoiceIndex',
        invoiceIndexDetail: 'BillService/InvoiceIndexDetail',
        getDiscountableItemDetail: 'BillService/DiscountableItemDetail',
        orderActionInvoiceDetail: 'BillService/OrderActionInvoiceDetail',
        getNotCanceledByFillter: 'BillService/GetNotCanceledByFillter',
        netAmountByStatmentId: 'BillService/NetAmountByStatmentId',
        getLastActionDateByStatementId: 'BillService/GetLastActionDateByStatementId',
        checkIfPaid: 'BillService/CheckIfPaid',
        approvalIndexDetails: 'BillService/ApprovalInvoiceDetail',
        cashStatementInvoiceNetUrl: 'BillService/CashStatementInvoiceNet',
        getElementsByStatementId: 'BillService/ElementsByStatementId',
        approveDiscount: 'BillService/ApprovePendingDiscount',
        pendingDiscount: 'BillService/PendingDiscount',
        checkActionDate: 'BillService/CheckActionDate'
    };

    patientEncounterInsurance = {
        url: 'PatientEncounterInsurance',
        getByFilter: 'PatientEncounterInsurance/ByFilter',
        getByEncounter: 'PatientEncounterInsurance/ByEncounter',
        getByEncounterIds: 'PatientEncounterInsurance/GetOutPatientEncounterInsuranceByEncounterId',
        getInPatientAccommodationClass: 'PatientEncounterInsurance/GetInPatientAccommodationClass',
    };

    coverageContract = {
        url: 'CoverageContract',
        getCoverageContractsByPayerId: 'CoverageContract/GetCoverageContractsByPayerId',
        getPayerCoverageContractEndDateAndStartDate: 'CoverageContract/PayerCoverageContractEndDateAndStartDate',
        copyContract: 'CoverageContract/CopyContractClass',
        getInfoByPayer: 'CoverageContract/InfoByPayer',
        getBySearch: 'CoverageContract/BySearch'
    };

    coverageClass = {
        url: 'CoverageClass',
        getClassesByContractId: 'CoverageClass/ClassesByContractId',
        getActiveClassesByContractId: 'CoverageClass/ActiveClassesByContractId',
        getPayerClassByPayerIdContainContract: 'CoverageClass/PayerClassByPayerIdContainContract',
        getInfoByContract: 'CoverageClass/InfoByContract',
        getBySearch: 'CoverageClass/BySearch'
    };

    coverageApprovalSection = {
        url: 'CoverageApprovalSection',
    };

    coverageApprovalDiagnosis = {
        url: 'CoverageApprovalDiagnosis',
    };


    statementTransaction = {
        url: 'StatementTransaction',
        downPayment: 'StatementTransaction/DownPayment',
        payment: 'StatementTransaction/Payment',
        refund: 'StatementTransaction/Refund',
        statementTransactions: 'StatementTransaction/StatementTransactions',
        getFundStatementTransactionsUrl: 'StatementTransaction/FundStatementTransactions',
        downPaymentCancelation: 'StatementTransaction/DownPaymentCancelation',
        discountCancelation: 'StatementTransaction/DiscountCancelation',
        invoiceCancelation: 'StatementTransaction/InvoiceCancelation',
        mainFundTransactionDetails: 'StatementTransaction/MainFundTransactionDetails',
        mainFundReceiveTransaction: 'StatementTransaction/MainFundReceiveTransaction',
        getSapFailedIncomingPaymentsUrl: 'StatementTransaction/SapFailedIncomingPayments',
        retrySapFailedIncomingPaymentsUrl: 'StatementTransaction/RetrySapFailedIncomingPayments',
        getSapFailedOutgoingPaymentsUrl: 'StatementTransaction/SapFailedOutgoingPayments',
        retrySapFailedOutgoingPaymentsUrl: 'StatementTransaction/RetrySapFailedOutgoingPayments',
        retrySapFailedCancelDownPaymentsUrl: 'StatementTransaction/RetrySapFailedCancelDownPayments',
        retrySapFailedRefundPaymentsUrl: 'StatementTransaction/RetrySapFailedRefundPayments',
        settlePatientBalanceUrl: 'StatementTransaction/SettlePatientBalance',
        searchTransactionStatements: 'StatementTransaction/SearchTransactionStatements',
        exportTransactionStatements: 'StatementTransaction/ExportTransactionStatements',
        pendingFundStatementTransactions: 'StatementTransaction/IsFoundPendingRefundTransaction',
        getApproveRequest: 'StatementTransaction/GetApproveRequests',
        approveRefund: 'StatementTransaction/ApprovedRefund',
        approveDiscount: 'StatementTransaction/ApprovaedPendingDiscount',
        pendingDiscountTransaction: 'StatementTransaction/IsFoundPendingDiscountTransaction',
        patientDeposit: 'StatementTransaction/PatientDeposit',
        patientBalanceTransaction: 'StatementTransaction/PatientBalanceTransaction',
        depositList: 'StatementTransaction/DepositList',
        calculateRemainingAmount: 'StatementTransaction/CalculateRemainingAmount',
        cancelFinanceSettlement: 'StatementTransaction/CancelFinanceSettlement',
    };

    elementsConfiguration = {
        url: 'BillElement',
    };

    payer = {
        url: 'Payer',
        getChildPayers: 'Payer/GetChildPayers',
        byQuery: 'Payer/ByQuery',
        activePayersSearch: 'Payer/ActivePayersSearch',
        getPayersContainContracts: 'Payer/PayersContainContracts',
        getSapFailedPayersRegistrationsUrl: 'Payer/SapFailedPayersRegistrations',
        retrySapFailedPayersRegistrationsUrl: 'Payer/RetrySapFailedPayerRegistrations',
        getPayersBySearchChild: 'Payer/PayersBySearchChild',
        getPayersHasParent: 'Payer/PayersHasParent',
    };

    payerContactPerson = {
        url: 'PayerContactPerson'
    }

    payerSession = {
        url: 'PayerSession',
        active: 'PayerSession/active',
    }

    approvalservices = {
        url: 'CoverageApprovalService',
        updateByActionId: 'CoverageApprovalService/UpdateServiceByActionId',
        getItemByApprovalId: 'CoverageApprovalService/GetItemByApprovalId',
    };

    item = {
        url: 'Item',
        bySearch: 'Item/BySearch',
        bySearchPaged: 'Item/BySearchPaged',
        suggestedMainItem: 'Item/SuggestedMainItem',
        LabParents: 'Item/LabParents',
        getServiceByCategory: 'Item/GetServiceByCategory',
        isMultiDentalParent: 'Item/IsMultiDentalParent',
        isItemHasDentalParent: 'Item/IsItemHasDentalParent',
        activeParentsAndLeaf:'Item/ActiveParentsAndLeaf'
    };

    itemInstruction = {
        url: 'ItemInstruction',
    };

    cashBoxTranfer = {
        url: 'CashTranferRequest',
        pendingRequest: 'CashTranferRequest/PendingRequest',
        requestDetailsUrl: 'CashTranferRequest/RequestDetails',
        sendTransferRequestUrl: 'CashTranferRequest/TransferRequest',
        acceptTransferRequestUrl: 'CashTranferRequest/AcceptTransferRequest',
        rejectTransferRequestUrl: 'CashTranferRequest/RejectTransferRequest',
        retrySapDispatchMoney: 'CashTranferRequest/RetrySapDispatchMoney',
        changeActualAmount: 'CashTranferRequest/ChangeActualAmount'
    };

    itemRelation = {
        url: 'ItemRelation',
        itemCountByMainItemId: 'ItemRelation/RelatedItemCountByMainItemId',
        medicationProductItemRelation: 'ItemRelation/MedicationProductItemRelation',
    };

    itemCategory = {
        url: 'ItemCategory',
        activeCategories: 'ItemCategory/AllCategories',
    };

    revenueSectionDefinition = {
        url: 'RevenueSectionDefinition',
        byCategoryAndOrderSection: 'RevenueSectionDefinition/ByCategoryAndOrderSection',
        pagedResults: 'RevenueSectionDefinition/PagedResults',
    };

    parentItem = {
        url: 'ParentItem',
        leaf: 'ParentItem/Leaf',
        tree: 'ParentItem/Tree',
        suggestedChildItem: 'ParentItem/SuggestedChildItem',
        itemBySearch: 'ParentItem/ItemBySearch',
    };

    packageItem = {
        url: 'PackageItem',
        suggestedPackageItem: 'PackageItem/SuggestedPackageItem',
        packageItemBySearch: 'PackageItem/PackageItemBySearch',
    };

    groupItem = {
        url: 'GroupItem',
        suggestedGroupItem: 'GroupItem/SuggestedGroupItem',
        groupItemBySearch: 'GroupItem/GroupItemBySearch',
    };

    orderSetItem = {
        url: 'OrderSetItem',
        suggestedOrderSetItem: 'OrderSetItem/SuggestedOrderSetItem',
        orderSetItemBySearch: 'OrderSetItem/OrderSetItemBySearch',
        orderSetItemById: 'OrderSetItem/OrderSetItemById'
    };

    coverageService = {
        url: 'CoverageService',
        getServicesByClassId: 'CoverageService/ServicesByClassId',
    };

    coverageDiscount = {
        url: 'CoverageDiscount',
        discountsByClassId: 'CoverageDiscount/DiscountsByClassId',
    };

    billSerials = {
        url: 'BillSerial',
    };

    billElement = {
        url: 'BillElement',
        getAllElement: 'BillElement/AllElement',
        allActiveElement: 'BillElement/AllActiveElement',
        serviceHaveBillElement: 'BillElement/ServiceHaveBillElement'
    };

    packageSession = {
        url: 'PackageSession',
    };

    patientPackageSession = {
        url: 'PatientPackageSession',
    };

    patientPayer = {
        url: 'PatientPayer',
        getById: 'PatientPayer/PatientPayerById?id=',
        getByPatientId: 'PatientPayer/PatientPayerByPatientId?patientId=',
        isHavePayer: 'PatientPayer/IsHavePayer?patientId=',
        getPayerInfo: 'PatientPayer/GetPayerInfo?patientId=',
        getPreferencePatientPayerInformation: 'PatientPayer/PreferencePatientPayerInformation',
        getPreferencePatientPayerAndContractInformation: 'PatientPayer/PreferencePatientPayerAndContractInformation',
        sessionPeriod: 'PatientPayer/SessionPeriod',
        patientsPayerInfo: 'PatientPayer/PatientsPayerInfo',
        isHavePayerApproval: 'PatientPayer/IsHavePayerApproval',
        activePatientPayerBasiInformation: 'PatientPayer/ActivePatientPayerBasiInformation',
    };

    priceList = {
        url: 'PriceList',
        getAllActivePriceList: 'PriceList/GetAllActivePriceList',
        getEmptyPriceListItems: 'PriceList/GetEmptyPriceListItems',
        getNonEmptyPriceListItems: 'PriceList/GetNonEmptyPriceListItems',
        exportPriceList: 'priceList/Export',
        importPriceList: 'priceList/Import',
    };

    priceListItem = {
        url: 'PriceListItem',
    };

    priceCopyList = {
        url: 'PriceCopyList',
        copy: 'PriceCopyList/Copy',
    };

    pricingCategory = {
        url: 'PricingCategory',
    };

    favoriteList = {
        url: 'FavoriteList',
        getById: 'FavoriteList/GetById',
        getByUser: 'FavoriteList/GetByUser',
        addList: 'FavoriteList/AddList',
    };

    pendingOrder = {
        url: 'PendingOrderTransaction'
    };

    staff = {
        url: 'Staff',
        getCashiersUrl: 'Staff/Cashiers',
        getByQueryUrl: 'Staff/ByQuery',
        getCashiersByQueryUrl: 'Staff/CashiersByQuery',
        getActiveStaff: 'Staff/ActiveStaff',
        getOrderedDoctor:'Staff/GetOrderedDoctor'
    };

    patientBill = {
        url: 'PatientBill',
        sapFailedActivePatientsBillsUrl: 'PatientBill/SapFailedActivePatientsBills',
        retrySapFailedActivePatientsBillsUrl: 'PatientBill/RetrySapFailedActivePatientsBills',
        retryAllSapFailedActivePatientsBillsUrl: 'PatientBill/RetryAllSapFailedActivePatientsBills',
        originalIssueDate:'PatientBill/OriginalIssueDate',
        checkStatementIsIssued:'PatientBill/CheckStatementIsIssued'
    };

    unitConversion = {
        url: 'UnitConversion',
    };

    payerSettlement = {
        allSettlement: 'PayerSettlement/AllSettlementsList',
        PayerSettlementsByRecieptNoSearch: 'PayerSettlement/PayerSettlementsByRecieptNoSearch',
        PayerSettlement: 'PayerSettlement',
        changeStatus: 'PayerSettlement/ChangeStatus',
        payerSettlementsByPayerSearch: 'PayerSettlement/PayerSettlementsByPayerSearch',
        payerSettlementsByStatusSearch: 'PayerSettlement/PayerSettlementsByStatusSearch',
        SettlementSummary: 'PayerSettlement/Summary'
    };

    payerReceipt = {
        payerReceiptByPayerSearch: 'PayerReceipt/PayerReceiptByPayerSearch',
        getAll: 'PayerReceipt/AllPayerReceipts',
        changeStatus: 'PayerReceipt/ChangeStatus',
        post: 'PayerReceipt',
    };

    bank =
        {
            url: "Bank",
            all: 'Bank/All',
            active: 'Bank/Active',
        };

    cashierDiscount =
        {
            url: 'CashierDiscount',
            privateActiveUrl: 'CashierDiscount/Private/Active'
        };

    settlementInvoice = {
        url: 'SettlementInvoice',
        all: 'SettlementInvoice/All',
        subset: 'SettlementInvoice/Subset',
        taxAmount: 'SettlementInvoice/TaxAmount',
    };

    assignment = {
        url: 'Assignment',
        byId: 'Assignment/ById',
        bySearch: 'Assignment/BySearch',
        byQuery: 'Assignment/ByQuery',
        byItemId: 'Assignment/GetByItemId',
    };

    assignmentRole = {
        url: 'AssignmentRole',
        bySearch: 'AssignmentRole/BySearch',
    };

    contractStationCoverageService = {
        url: 'ContractService',
        servicesByContractId: 'ContractService/ServicesByContractId',
    };

    contractStationCoverageDiscount = {
        url: 'ContractDiscount',
        discountsByContractId: 'ContractDiscount/DiscountsByContractId',
    };

    visaType = {
        url: 'VisaType',
        all: 'VisaType/All',
        allActive: 'VisaType/AllActive',
    };

    packagePlan = {
        url: 'PackagePlan',
        getByPatientAndPackageIdUrl: 'PackagePlan/ByPatientAndPackageId',
    };

    doctorSettlement = {
        url: 'DoctorSettlement',
        bySearch: 'DoctorSettlement/BySearch',
        cancel: 'DoctorSettlement/Cancel',
        approve: 'DoctorSettlement/Approve',
    }

    doctorSettlementDetail = {
        url: 'DoctorSettlementDetail',
        byDoctorSettlement: 'DoctorSettlementDetail/ByDoctorSettlement',
        deleteDoctorSettlementById: 'DoctorSettlementDetail/DeleteDoctorSettlementById',
    }

    doctorBalance = {
        url: 'DoctorBalance',
        getDoctorBalance: 'DoctorBalance/GetDoctorBalance',
    };

    systemCurrency = {
        url: 'SystemCurrency',
        defaultCode: 'SystemCurrency/DefaultCode',
        default: 'SystemCurrency/Default',
        all: 'SystemCurrency/All',
        add: 'SystemCurrency/AddCurrency',
        update: 'SystemCurrency/UpdateCurrency',
    };


    excludedSections = {
        url: 'ExcludedSections',
        getExcludedSections: 'ExcludedSections/AllExcludedSections',
    };

    excludedDiagnosis = {
        url: 'ExcludedDiagnosis',
        getExcludedSections: 'ExcludedDiagnosis/AllExcludedDiagnosis',
    };

    payerTemplate = {
        url: 'PayerTemplate',
        getAllActivePayerTemplate: 'PayerTemplate/AllActive'
    };

    templateMasterCode = {
        url: 'TemplateMasterCode',
        getByPayerTemplateIdUrl: 'TemplateMasterCode/GetByPayerTemplateId',
        exportTemplateMasterCodes: 'TemplateMasterCode/ExportTemplateMasterCodes',
        importPayerTemplateCodes: 'TemplateMasterCode/ImportTemplateMasterCode',
    };

    payerDiscount = {
        url: 'PayerDiscount',
        discountsByPayerId: 'PayerDiscount/DiscountsByPayerId',
    };

    payerDiagnosisGroup = {
        url: 'PayerDiagnosisGroup',
        getAll: 'PayerDiagnosisGroup/GetAll',
        getAllActive: 'PayerDiagnosisGroup/GetAllActive',
    };

    PayerDiagnosisGroupDetails = {
        url: 'PayerDiagnosisGroupDetails',
    };

    settlementDeductionSetup = {
        url: 'SettlementDeductionSetup',
        getAccounts: 'SettlementDeductionSetup/Accounts',
        getActive: 'SettlementDeductionSetup/Active',
    };

    currencyLog = {
        url: 'CurrencyLog',
    };

    approvalAndStaffNote = {
        url: 'ApprovalAndStaffNote',
    };

    pendingAreaInfoNote = {
        url: 'PendingAreaInfoNote',
        bySearch: 'PendingAreaInfoNote/BySearch',
        add: 'PendingAreaInfoNote/Add',
    };

    pendingAreaInfo = {
        url: 'PendingAreaInfo',
        recalculatePriceInfo: 'PendingAreaInfo/RecalculatePriceInfo',
        recalculatePriceByOrerDetailIds: 'PendingAreaInfo/RecalculatePriceByOrerDetailIds'
    };

    receiveOrderSetup = {
        url: 'ReceiveOrderSetup',
        byStaff: 'ReceiveOrderSetup/ByStaff',
    };

    payerService = {
        url: 'PayerService',
        byPayerId: 'PayerService/ByPayerId',
    };

    payerExcludedSection = {
        url: 'PayerExcludedSection',
        allPayerExcludedSection: 'PayerExcludedSection/AllPayerExcludedSection',
    };

    payerExcludedDiagnosis = {
        url: 'PayerExcludedDiagnosis',
        allPayerExcludedDiagnosis: 'PayerExcludedDiagnosis/AllPayerExcludedDiagnosis',
    };

    parentPayerSession = {
        url: 'ParentPayerSession',
        all: 'ParentPayerSession/All',
        allActive: 'ParentPayerSession/AllActive',
    };

    radiologyTemplate = {
        url: 'RadiologyTemplate',
    };

    radiologyReportTemplate = {
        url: 'RadiologyReportTemplate',
        byActionId: "RadiologyReportTemplate/ByActionId",
        logByTemplateId: "RadiologyReportTemplate/RadiologyReportTemplateLog",
        logByActionId: "RadiologyReportTemplate/RadiologyReportTemplateLogByActionId",
        reopenRadiologyReportIfApprovedUser:"RadiologyReportTemplate/ReopenRadiologyReportIfApprovedUser"

    };

    chargingSetup = {
        url: 'ChargingSetup',
        byFacility: 'ChargingSetup/ByFacility',
    };

    consultationRequest = {
        url: 'ConsultationRequest',
        byRequestId: 'ConsultationRequest/GetById',
        changeTransferStatus: 'ConsultationRequest/ChangeTransferStatus',
        transferConsultation: 'ConsultationRequest/TransferConsultation',
        changeChargeableStatus: 'ConsultationRequest/ChangeChargeableStatus',
    }

    consultationPendingAreaInfoNotes = {
        url: 'ConsultationPendingAreaInfoNotes',
        bySearch: 'ConsultationPendingAreaInfoNotes/BySearch',
        add: 'ConsultationPendingAreaInfoNotes/Add'
    }

    PatientBalance = {
        url: 'PatientBalance',
        getPatientBalance: 'PatientBalance/GetPatientBalanceByPayerId',
        getPatientBalanceDetails: 'PatientBalance/GetPatientBalanceDetailsByApprovalNo'
    }

    EclaimSetup = {
        url: 'EclaimSetup'
    }

    CashDiscountSetup = {
        url: 'CashDiscountSetup'
    }

    FDASetup = {
        url: 'FDASetup'
    }

    
    itemPriceApprove = {
        url: 'ItemPriceApprove',
        addItemPriceList: 'ItemPriceApprove/AddPriceList',
        updateItemPriceList: 'ItemPriceApprove/UpdatePriceList',
        addItemPriceService: 'ItemPriceApprove/AddItemPriceService',
        updateItemPriceService:'ItemPriceApprove/UpdateItemPriceService',
        rejectItemPriceApprove: 'ItemPriceApprove/RejectItemPriceApprove',
        approveItemPriceApprove: 'ItemPriceApprove/ApproveItemPriceApprove',
    };

    allowedServicesSection = {
        url: 'AllowedServicesSection',
        add: 'AllowedServicesSection/add',
        update: 'AllowedServicesSection/update',
    };

    claimPatientAttachment = {
        url: 'ClaimPatientAttachment',
        getById: 'ClaimPatientAttachment/ById'
    }
}
