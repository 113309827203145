import { SelectItem } from 'primeng/api';
import { BedStatus } from '../enums/bed-statuses';

export class BedStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Occupied', value: BedStatus.Occupied },
            { label: 'Out Of Service', value: BedStatus.OutOfService },
            { label: 'Reserved', value: BedStatus.Reserved },
            { label: 'Under House keeping', value: BedStatus.UnderHousekeeping },
            { label: 'Under Maintenance', value: BedStatus.UnderMaintenance },
            { label: 'Vacant', value: BedStatus.Vacant }
        ];
    }

    static getAvailableLookup(): SelectItem[] {
        return [
            { label: 'Out Of Service', value: BedStatus.OutOfService },
            { label: 'Reserved', value: BedStatus.Reserved },
            { label: 'Under House keeping', value: BedStatus.UnderHousekeeping },
            { label: 'Under Maintenance', value: BedStatus.UnderMaintenance },
            { label: 'Vacant', value: BedStatus.Vacant }
        ];
    }

    static getTitleById(id) {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }
}
