export class PatientOrderService {

    OrderId: number;
    OrderDetailId: number;
    OrderCancelReason: string;
    ItemId: number;
    ItemTitle: string;
    ItemForeignTitle: string;
    ItemCategoryId: number;
    ItemCategoryTitle: string;
    ItemCategoryForeignTitle: string;
    Status: number;
    VisitDate: Date;
    OrderDate: Date;
    OrderSectionTitle: string;
    OrderSectionForeignTitle: string;
    OrderByName: string;
    OrderByOtherName: string;
    OrderSource: number;
    IsExecludedItem?: boolean;
    DealingType?: number;
    StatmentStatus: number;
    CreatedUser: string;
    Quantity?: number;
    ItemCode: string;
    OrderDoctorId: number;
    OrderActionElementId: number;
    UnitDescription: string;
    Note?: string;
    ActionItemCategoryId: number;
    NetAmount: number;
    ModifiedBy: string;
    ReceiveStatus:number;

    constructor() {

    }

}
