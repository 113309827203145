export const environment = {
  production: true,
  useAuthService: true,
  admission: "http://his.certacure.com:8083/api/",
  core: "http://his.certacure.com:8084/api/",
  lab: "http://his.certacure.com:8085/api/",
  pharmacy: "http://his.certacure.com:8087/api/",
  nurse: "http://his.certacure.com:8098/api/",
  queryModel: "http://his.certacure.com:8088/api/",
  order: "http://his.certacure.com:8089/api/",
  authorityService: "http://his.certacure.com:9090",
  redirectURI: "http://his.certacure.com/callback",
  silentRedirectURI: "http://his.certacure.com/silent",
  postLogoutRedirectURI: "http://his.certacure.com",
  reportViewer: "http://his.certacure.com:9092/Default.aspx",
  reportViewerWithParams: "http://his.certacure.com:9092/ReportWithParam.aspx",
  crystalReportViewer: "http://his.certacure.com:9002/CrystalReport.aspx",
  specimenBarcodeReportViewer: "http://his.certacure.com:9092/LabSpecimen.aspx",
  notificationURI: "ws://his.certacure.com:8181/connect?access_token=",
  sendNotificationURI: "http://his.certacure.com:8181/api/Notification",
  coreImagePath: "http://his.certacure.com:8084/imageStorage/",
  dynamicFormViewer: "http://his.certacure.com:9095",
  dynamicFormPath: "http://his.certacure.com:9099/api/",
  webSocketRetry: 1,
  autoRefreshSeconds: 30,
  nursingManagement: "http://his.certacure.com:8090",
  eClaimIntegration: true,
  dimensionUrl: "http://his.certacure.com:9081/api/",
  dimension: "https://tpo-stg.ehc.jo/api/",
  dimensionAttachement: "https://tpo-stg.ehc.jo:8000/api/",
  dimensionLicenseID: "JOR-F-000011",
  dimensionUsername: "Istishari",
  dimensionPassword: "iii.123$",
  dimensionDemographicInfo: "https://PRD-EHSAPI.ehc.jo/Api/v1/index.php",
  appointment: "http://his.certacure.com:8093/api/",
  appointmentURI: "http://his.certacure.com:8094/",
  mediSpanIntegration: true,
  mobileIntegrationEnabled: true,
  mediSpanUrl: "http://his.certacure.com:8086/api/",
  operationManagement: "http://his.certacure.com:6601",
  cpoeManagement: "http://his.certacure.com:5515",
  queryBuilder: "http://his.certacure.com:21361/api",
  sqlBuilderEngine: "http://his.certacure.com:6602",
  worksheetTemplate: "_WorkSheet",
  notification: "http://his.certacure.com:8282/",
  enableNotification: true,
  enableOncology: true,
  oncology: "http://his.certacure.com:6604/api/",
  oncologyManagement: "http://his.certacure.com:6605",
  enableAppointment: true,
  outPatientMonitorIntervalCallback: 1,
  operation: "http://his.certacure.com:8081/api/",
  enableOperation: false,
  httpAggregatorApi: "http://his.certacure.com:6655/api/",
  specimenLISReportURI: "http://something/orderId=",
  allowMultiReturn: false,
  cateringManagement: "http://his.certacure.com:5555",
  discountSetup: 2, // 0 = Disable Discount, 1 = Enable Discount Approve, 2 = Discount Privilege
  labReportViewer: "http://his.certacure.com:9092/LabSpecimen.aspx",
};