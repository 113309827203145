import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable';
import { ServiceBase } from 'app/shared/infrastructure';
import { environment } from 'environments/environment';

@Injectable()
export class AggregatorPatientService extends ServiceBase {

    constructor(private _http: HttpClient) {
        super();
    }

    checkExpireDocuments = (id: number, isRaise: boolean): Observable<any> => {
        return this._http.get<any>(`${environment.httpAggregatorApi}Patient/CheckExpireDocuments?PatientId=${id}&IsRaise=${isRaise}`)
    }

    CheckEligibilityRequest = (payerId: number, documentNumber: string, documentType: number, purposeId: number): Observable<any> => {
        return this._http.get<any>(`${environment.httpAggregatorApi}Patient/CheckEligibilityRequest/${payerId}/${documentNumber}/${documentType}/${purposeId}`)
    }

    NaphiseCheckEligibility = (object): Observable<any> => {
        const data = {
            "payerId": object.payerId,
            "documentNumber": object.documentNumber,
            "documentType": object.documentType,
            "purposeIds": object.purposeIds,
            "contractId": object.contractId,
            "fromDate": object.fromDate.toLocaleDateString(),
            "toDate": object.toDate != null ? object.toDate.toLocaleDateString() : null,
        };

        return this._http.post<any>(`${environment.httpAggregatorApi}Patient/NaphiseCheckEligibilityByDocument`, JSON.stringify(data));
    }

    NaphiseCheckEligibilityByPatient = (data): Observable<any> => {
        data.fromDate = data.fromDate.toLocaleDateString();
        
        return this._http.post<any>(`${environment.httpAggregatorApi}Patient/NaphiseCheckEligibilityByPatientId`, JSON.stringify(data));
    }
}