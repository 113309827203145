import { SelectItem } from 'primeng/api';
import { TransferMethod } from '../enums/transfer-method';

export class TransferMethodLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All Transfer Methods', value: null },
            { label: 'Hospital', value: TransferMethod.Hospital },
            { label: 'Doctor', value: TransferMethod.Doctor },
            { label: 'Clinic', value: TransferMethod.Clinic },
        ];
    }

    static get(): SelectItem[] {
        return [
            { label: 'Hospital', value: TransferMethod.Hospital },
            { label: 'Doctor', value: TransferMethod.Doctor },
            { label: 'Clinic', value: TransferMethod.Clinic },
        ];
    }
}
