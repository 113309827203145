export class OrderActionViewModel {

    // shared
    OrderActionId: number;
    ItemCategoryId: number;
    ActionItemCategoryId: number;
    PatientName: string;
    OrderId: number;

    // Cancel order
    CancelReason: string;
    CancelQuantity: number;
    OrderQuantity: number;

    // Edit Doctor Order
    OrderDoctorId: number;
    NewOrderDoctorId: number;
    OrderNote?: string;
    NewOrderNote?: string;
    OrderDoctorApId: string;
    NewOrderDoctorApId: string;
    OrderCashDoctorApId: string;
    NewOrderCashDoctorApId: string;
    // Edit Is Execluded
    IsExecluded: boolean;
    NewIsExecluded: boolean;

    // Edit Item Element
    ItemElementId: number;
    NewItemElementId: number;
    OrderSource?: number;
    PatientId?: number;
    EncounterId?: number;
    PatientType?: number;

    constructor() {
    }
}
