import { SelectItem } from 'primeng/api';
import { AdmissionReportPage } from '../enums/admission-report-page';

export class AdmissionReportPageLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Admission Station', value: AdmissionReportPage.AdmissionStation },
            { label: 'Hospital Rooms', value: AdmissionReportPage.HospitalRooms },
            { label: 'New Admission', value: AdmissionReportPage.NewAdmission },
            { label: 'Patient Registration', value: AdmissionReportPage.PatientRegistration },
        ];
    }
}
