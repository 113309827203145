import { ReservationStatus } from '../enums/reservation-status';
import { SelectItem } from 'primeng/api';

export class ReservationStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Active', value: ReservationStatus.Active },
            { label: 'Administrate', value: ReservationStatus.Administrate },
            { label: 'Canceled', value: ReservationStatus.Canceled },
        ];
    }
}
