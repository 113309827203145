export enum CoverageClassType {
    Group = 1,
    SubGroup = 2,
    Class = 3,
    SubClass = 4,
    Plan = 5,
    SubPlan = 6,
    Sequence = 7,
    RxBin = 8,
    RxPcn = 9,
    RxId = 10,
    RxGroup = 11,
}
