import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { ServiceBase } from '../../../infrastructure/serviceBase.component';
import { CoreApiConfiguration } from '../coreApiConfiguration';

@Injectable()
export class ClaimAttachmentService extends ServiceBase {

    private _actionUrl: string;
    private _retry: string;
    private _generate: string;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this._actionUrl = _configuration.server;
        this._retry = this._actionUrl + _configuration.claimAttachment.retry;
        this._generate =  this._actionUrl + _configuration.claimAttachment.generate;
    }

    retry = (claimPatientAttachmentId: number): Observable<any> => {
        return this._http.get<any>(this._retry + '?claimPatientAttachmentId=' + claimPatientAttachmentId);
    }

    generate = (ids: any, isSeperated: boolean): Observable<any> => {
        return this._http.post<any>(`${this._generate}?isSeperated=${isSeperated}`, JSON.stringify(ids));
    }
}