import { SelectItem } from 'primeng/api';
import { NaphiseSeverityType } from '../enums/naphise-severity-type';

export class NaphiseSeverityTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Fatal', value: NaphiseSeverityType.Fatal },
            { label: 'Error', value: NaphiseSeverityType.Error },
            { label: 'Warning', value: NaphiseSeverityType.Warning },
            { label: 'Information', value: NaphiseSeverityType.Information }];
    }

    static getTitleById(id) {
        return this.getLookup().find(f => f.value === id)?.label || '';
    }
}
