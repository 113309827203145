export class CoverageClass {

     Id: number;
     Title: string;
     ForeignTitle: string;
     ContractId: number;
     PriceListId: number;
     IPApprovalRequired: boolean;
     IPAccommodationClassId: number;
     IPParticipantPercentage: number;
     IPBeneficiaryPercentage: number;
     IPSponsorPercentage: number;
     OPVisitCountAllowed?: number;
     OPRevisitPeriod?: number;
     OPRevisitCount?: number;
     OPParticipantPercentage: number;
     OPBeneficiaryPercentage: number;
     OPSponsorPercentage: number;
     ERVisitCountAllowed?: number;
     ERRevisitPeriod?: number;
     ERRevisitCount?: number;
     ERParticipantPercentage: number;
     ERBeneficiaryPercentage: number;
     ERSponsorPercentage: number;
     ClassCoverageSummary: string;
     Status: boolean;
     ContDiscountDischargePresc?: number;
     ChronicDuration?: number;
     MOHContractSetup?: any[];
     CoverageClassType?: number;
}
