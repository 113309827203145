export class PatientOrderCategorySearch {

    PatientId: number;
    EncounterId?: number;
    PatientTypeId?: number;

    constructor() {

    }
}
