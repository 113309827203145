export class PatientCancelOrderSearch {

    PatientId: number;
    CategoryId: number;
    EncounterId?: number;
    PatientTypeId?: number;
    ItemTitle: string;
    OrderActionStatus?: number;
    EncounterDate?: Date;
    OrderDate?: Date;
    OrderSectionId?: number;
    OrderById?: number;
    IsExcluded?: boolean;
    CreatedUser?: number;
    Quantity?: number;
    ItemCode: string;
    OrderId?:number;
    Page: number;
    Rows: number;

    constructor() {

    }
}
