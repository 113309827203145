export enum GroupCode {
    DiscountOn = 'DISCOUNT_ON',
    CoverageOn = 'COVERAGE_ON',
    DealingType = 'DEALING_TYPE',
    OpsStatus = 'OPS_STATUS',
    ReservationStatus = 'RESERVATION_STATUS',
    PreAdmissionStatus = 'PREADMISSION_STATUS',
    AdmissionStatus = 'ADMISSION_STATUS',
    BedStatus = 'BED_STATUS',
    GenderType = 'GENDER_TYPE',
    InsurancePriority = 'INSURANCE_PRIORITY',
    InsuranceType = 'INSURANCE_TYPE',
    MartialStatus = 'MARTIAL_STATUS',
    ReservationMethod = 'RESERVATION_METHOD',
    StatusType = 'STATUS_TYPE',
    TelephoneType = 'TELEPHONE_TYPE',
    RoomStatus = 'ROOM_STATUS',
    CurrencyType = 'CURRENCY_TYPE',
    Religion = 'RELIGION',
    DiscountType = 'DISCOUNT_TYPE',
    IsolationType = 'ISOLATION_TYPE',
    DietType = 'DIET_TYPE',
    AdmissionType = 'ADMISSION_TYPE',
    LimitType = 'LIMIT_TYPE',
    SapAppointmentType = 'SAP_APPOINTMENT_TYPE',
    AppointmentStatus = 'APPOINTMENT_STATUS',
    TransferReason = 'TRANSFER_REASON',
    PatientProblem = 'PATIENT_PROBLEM',
    BloodType = 'BLOOD_TYPE',
    DocumentType = 'DOCUMENT_TYPE',
    ResourcePeriodType = 'RESOURCE_PERIOD_TYPE',
    RadiologyModalities = 'RADIOLOGY_MODALITIES',
    AppointmentCancelReason = 'APPOINTMENT_CANCEL_REASON',
    PatientDiets = 'PATIENT_DIETS',
    ToothNumbers = 'TOOTH_NUMBER',
    LeaveTypes = 'LEAVE_TYPE',
    SURFACE_TYPE = 'SURFACE_TYPE',
    LANGUAGE = 'LANGUAGE',
    METHOD_COMM = 'METHOD_COMM',
    HEAR_US = 'HEAR_US',
    Occupation = 'OCCUPATION',
    AlternativeReason = 'ALTERNATIVE_REASON',
    EmergencyArrivalCode = 'EMERGENCY_ARRIVAL_CODE'
}
