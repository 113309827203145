import { AdmissionStatus } from '../enums/admission-status';
import { SelectItem } from 'primeng/api';

export class DoctorReleaseLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Open', value: AdmissionStatus.InHospital },
            { label: 'Doctor Release', value: AdmissionStatus.ReleasePermission }];
    }
}
