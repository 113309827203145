import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class CoreApiConfiguration {

    server: string;
    coreImagePath: string;
    dynamicFormPath: string;

    constructor() {
        this.server = environment.core;
        this.coreImagePath = environment.coreImagePath;
        this.dynamicFormPath = environment.dynamicFormPath;
    }

    facility =
        {
            url: 'Facility',
        };

    patient = {
        url: 'Patient',
        getPatientInformationFromStaffByStaffId: 'Patient/GetPatientInformationFromStaffByStaffId',
        getSapFailedPatientRegistrationsUrl: 'Patient/SapFailedPatientRegistrations',
        retrySapFailedPatientRegistrationsUrl: 'Patient/RetrySapFailedPatientRegistrations',
        activePatientBasicInfoByName: 'Patient/ActivePatientBasicInfoByName',
        allPatientBySearch: 'Patient/AllPatientBySearch',
        mergePatient: 'Patient/MergePatient',
        searchPatient: 'Patient/SearchPatient',
        searchByPatientQuery: 'Patient/SearchPatient',
        addPatient: 'Patient/AddPatient',
        addPatientShortcut: 'Patient/AddPatientShortcut',
        patientMoreInfo: 'Patient/PatientMoreInfo',
        activeFemalePatient:'Patient/GetFemalePatients',

    };

    name = {
        url: 'Name',
    };

    doctorClinic = {
        url: 'DoctorClinic',
        doctorsLookUpByClinic: 'DoctorClinic/DoctorsLookUpByClinic',
        doctorsByClinic: 'DoctorClinic/DoctorsByClinic'
    };

    patientVisit = {
        url: 'PatientVisit',
        walkInVisit: 'PatientVisit/WalkInVisit',
        erVisit: 'PatientVisit/ErVisit',
        getOpenVisitByPatientId: 'PatientVisit/OpenVisitByPatientId',
        patientVisitByDoctorId: 'PatientVisit/DoctorPatient',
        patientVisitByNurseId: 'PatientVisit/NurseSectionsPatient',
        listAllErVisitsOfPatient: 'PatientVisit/ListAllErVisitsOfPatient',
        visitStatusById: 'PatientVisit/VisitStatusById',
        patientVisitBySearch: 'PatientVisit/PatientVisitBySearch',
        reOpenPatientVisit: 'PatientVisit/ReOpenPatientVisit',
        getVisitDate: 'PatientVisit/VisitDate',
        dischargePatient: 'PatientVisit/DischargePatient',
        notifyPyxisOnPatientUpdate: 'PatientVisit/NotifyPyxisOnPatientUpdate',
        publishVisitHistory: 'PatientVisit/VisitHistory',
        openVisit: 'PatientVisit/OpenVisit',
        patientVisitsToday: 'PatientVisit/PatientVisitsToday',
        getFollowUpCount: 'PatientVisit/FollowUpCount',
        walkInVisitCount: 'PatientVisit/WalkinVisitCount',
        notifyPacsOnPatientUpdate: 'PatientVisit/NotifyPACsOnPatientUpdate',
        updateVisitDoctorAndSection: 'PatientVisit/UpdateDrAndSectionForVisit',
    };

    lookup =
        {
            getFacility: 'LookUp/GetFacility',
            getCity: 'LookUp/GetCity',
            getCountry: 'LookUp/GetCountry',
            getCityByCountryId: 'LookUp/getCityByCountryId',
            getNationality: 'LookUp/GetNationality',
            getDiagnose: 'LookUp/GetDiagnose',
            filterDiagnose: 'LookUp/filterDiagnose',
            getCodesByGroupKey: 'LookUp/GetCodesByGroupKey',
            getStaff: 'LookUp/GetStaff',
            getSectionByMedicalCategory: 'LookUp/GetSectionByMedicalCategory',
            getNurseSectionByMedicalCategory: 'LookUp/GetNurseSectionByMedicalCategory',
            getGetOrganizationSection: 'LookUp/GetOrganizationSection',
            getOrganizationSectionById: 'LookUp/GetOrganizationSectionById',
            getDoctorStaff: 'LookUp/GetDoctorStaff',
            getFilteredDoctorStaff: 'LookUp/GetFilteredDoctorStaff',
            getFilteredDoctorStaffWithCode: 'LookUp/GetFilteredDoctorStaffWithCode',
            getFilteredStaff: 'LookUp/GetFilteredStaff',
            getActiveFilteredStaff: 'LookUp/GetActiveFilteredStaff',
            getOutPatientClinicsSection: 'LookUp/GetOutPatientClinicsSection',
            getOutPatientClinicsSectionByLoggedInNurse: 'LookUp/GetOutPatientClinicsSectionByLoggedInNurse',
            url: 'LookUp',
            getItem: 'LookUp/GetItem',
            getFilteredItem: 'Lookup/GetFilteredItem',
            getFilteredLeafItem: 'Lookup/GetLeafItems',
            getAllMedicalCategorySection: 'LookUp/GetAllMedicalCategorySection',
            getParentItems: 'LookUp/GetParentItems',
            getPriceList: 'LookUp/PriceLists',
            getFilteredAdmissionDoctorStaff: 'LookUp/GetFilteredAdmissionDoctorStaff',
            getStaffById: 'LookUp/GetStaffById',
            sectionForEncounter: 'LookUp/SectionForEncounter',
            getAllParentAndLeafMedicalCategorySections: 'LookUp/GetAllParentAndLeafMedicalCategorySections',
            getAllMedicalCategoryUserSections: 'LookUp/GetAllMedicalCategoryUserSections',
            getCashierStaff: 'LookUp/CashierStaff',
            getStaffExcludedRegistered: 'LookUp/StaffExcludedRegistered',
            getAllActiveStaffInfo: 'LookUp/GetAllActiveStaffInfo',
            getAllActiveNurses: 'Lookup/GetAllActiveNurses',
            getStaffsByStaffIds: 'Lookup/GetStaffsByStaffIds',
            getActiveMedicalCategorySection: 'Lookup/GetActiveMedicalCategorySection',
            isVisitSectionER: 'Lookup/IsPatientVisitSectionER',
            getFilteredActiveStaff: 'LookUp/GetFilteredActiveStaff',
            sectionsByPatientType: 'LookUp/SectionsByPatientType',
        };

    diagnose = {
        url: 'Diagnose',
        byTitle: 'Diagnose/ByTitle',
        bySearch: 'Diagnose/BySearch',
        bySearchWithLeafAndParent: 'Diagnose/BySearchWithLeafAndParent',

    };

    code = {
        getCodesByGroupKey: 'Code/GetCodesByGroupKey',
        getCodeGroups: 'Code/GetCodeGroups',
        url: 'Code',
        getActiveCodesByGroupKey: 'Code/GetActiveCodesByGroupKey',
    };

    patientEncounterProblem = {
        get: '/ProblemList',
        getActiveProblems: '/GetActiveProblems',
        getById: '/ById',
        url: 'PatientEncounterProblem',
    };

    medicalCategory = {
        url: 'MedicalCategory',

    };

    patientDocument = {
        url: 'PatientDocument',
    };

    patientKin = {
        url: 'PatientKin',
    };

    fileRequest = {
        url: 'FileRequest',
    };

    allergy = {
        url: 'Allergy',
    };

    printTemplateSetup = {
        url: 'PrintTemplateSetup',
    }

    store = {
        url: 'Store',
        getLookupUrl: 'Store/Lookup',
        getLookupByIdUrl: 'Store/LookupById',
    };

    OganizationSection = {
        url: 'OrganizationSection',
        all: 'OrganizationSection/All',
        inPatientSections: 'OrganizationSection/InPatientSections',
        allActive:'OrganizationSection/AllActive',
        getByPatientType:'OrganizationSection/GetByPatientType'
    };

    pblSetup = {
        url: 'PBLSetup',
    };
    
    dynamicFormsSetup = {
        url: 'DynamicFormsSetup',
    };

    cpoeSetup = {
        url: 'CPOESetup',
    };
    allergyType = {
        url: 'AllergyType',
    };

    patientAllergy = {
        url: 'PatientAllergy',
        byPatientId: 'PatientAllergy/ByPatientId',
        activeByPatientId: 'PatientAllergy/ActiveByPatientId',
    };

    patientDiagnosis = {
        url: 'PatientDiagnosis',
        byPatientEncounter: 'PatientDiagnosis/ByPatientEncounter',
        allByPatientEncounter: 'PatientDiagnosis/AllByPatientEncounter',
    };

    erArea ={
        url: 'ErArea',
        byId: 'ErArea/ById'
    };
     
    erAreaBeds = {
        url: 'ERAreaBed',
        byERAreaUrl : 'ERAreaBed/ByERArea',
        getAvailableErBedById: 'ERAreaBed/ByERAvailableBeds',
    };

    staff = {
        url: 'Staff',
        getCashiersUrl: 'Staff/Cashiers',
        getByQueryUrl: 'Staff/ByQuery',
        getSalesPersonsUrl: 'Staff/SalesPersons',
        doctorOnly: 'Staff/doctorOnly',
        staffById: 'Staff/ById',
        staffByMedicalCategory: 'Staff/ByMedicalCategory',
        getActiveDoctorStaffUrl: 'Staff/ActiveDoctorStaff',
        activeDoctors: 'Staff/ActiveDoctors'
    };

    facilityUnit =
        {
            url: 'FacilityUnit',
            getFacilityUnitNurses: 'FacilityUnit/FacilityUnitNurses',
            getFacilityUnitSections: 'FacilityUnit/FacilityUnitSections',
            getAllNursesSections: 'FacilityUnit/GetAllNurseSections',
            insertFacilityUnitSection: 'FacilityUnit/FacilityUnitSection',
            updateFacilityUnitSection: 'FacilityUnit/FacilityUnitSection',
            insertFacilityUnitNurse: 'FacilityUnit/FacilityUnitNurse',
            updateFacilityUnitNurse: 'FacilityUnit/FacilityUnitNurse',
            getNurseBySectionId: 'FacilityUnit/GetNurseBySectionId',
            getBySearch: 'FacilityUnit/BySearch',
        };

    userSection = {
        url: 'UserSection',
        getByStaffId: 'UserSection/GetByStaffId',
    };

    diseaseCode =
        {
            url: 'DiseaseCode',
            Bytitle: 'DiseaseCode/DiseaseCodeByTitle',
        };

    speciality =
        {
            url: 'speciality',
            getAll: 'speciality/GetAll',
            getAllActive: 'speciality/GetAllActive',
        };

    patientInfectious =
        {
            url: 'PatientInfectious',
            byEncounters: 'PatientInfectious/ByEncounters',
            isPatientInfectious:'PatientInfectious/IsPatientInfectious',
            isPatientInfectiousByPatientId: 'PatientInfectious/IsPatientInfectiousByPatientId'
        };

    bank =
        {
            url: 'Bank',
            getByBankId: 'Bank/GetById',
        };

    report = {
        url: 'Report',
        prefixes: 'Report/all',

    };

    favoritePageStaff = {
        url: 'FavoritePageStaff',
    };
    timelineHistory = {
        historyInformation: 'Patient/HistoryInfo',
        visitPatientHistory: 'PatientVisit/PatientHistory',
        admissionPatientHistory: 'Admission/PatientHistory',
        patientEncounterProblem: 'PatientEncounterProblem/ProblemListDetails'

    };

    patientVitalSign = {
        url: 'PatientVitalSign',
        latestThreeByEncounter: 'PatientVitalSign/LatestThreeByEncounter',
        patientVitalSignInfo: 'PatientVitalSign/PatientVitalSignInfo',
    }

    patientRiskFactor = {
        url: 'patientRiskFactor',
    }

    carePlanShortcut = {
        url: 'carePlanShortcut',
        search: 'carePlanShortcut/search',
        getAllActive: 'carePlanShortcut/getAllActive',
    }

    patientDiet = {
        url: 'PatientDiet',
    }

    systemAllergy = {
        url: 'SystemAllergy',
        getBySearch: 'SystemAllergy/BySearch',
    }

    medicationFileSetup = {
        url: 'CloseMedicalFileSetup',
    };

    blockedMedicalFileSetup = {
        url: 'BlockedCloseMedicalFileSetup',
    }

    executeQuery = {
        url: 'ExecuteQuery',
    };

    notificationRole = {
        url: 'NotificationRole',
    };

    sessionVisit = {
        url: 'SessionVisit',
        activeByPatient: 'SessionVisit/ActiveByPatient',
        byPatient:'SessionVisit/ByPatient',
        bySearch: 'SessionVisit/BySearch',
        openPatientSession: 'SessionVisit/OpenPatientSession',
        closePatientSession: 'SessionVisit/ClosePatientSession',
        sessionEncounters: 'SessionVisit/getSessionEncounters',
        infoByPatient:'SessionVisit/InfoByPatient',
    };

    smsSetup = {
        url: 'SMSSetup',
        configurationUrl: 'SMSSetup/Configuration'
    };

    clinicalDocumentaionPermission = {
        url: 'ClinicalDocumentaionPermission',
    };

    radiologySetup = {
        url: 'RadiologySetup',
    };

    triageScaleSetup = {
        url: 'TriageScaleSetup',
    };
    
    mrnSetup = {
        url: 'MRNSetup',
    };

    pharmacySetup = {
        url: 'PharmacySetup',
        getActive: 'PharmacySetup/Active',
    };
    pharmacyRegisterationSetup={
        url:'PharmacyRegisterationSetup'
    }

    mobileSetup = {
        url: 'MobileSetup',
    };

    patientProblem = {
        url: 'PatientProblem',
        getAll: 'PatientProblem/GetAll',
    };

    vitalSigns = {
        url: 'VitalSigns',
        getAll: 'VitalSigns/All',
        getVitalSignTypes: 'VitalSigns/VitalSignTypes'
    };

    vitalSignNormalRang = {
        url: 'VitalSignNormalRange',
        getAll: 'VitalSignNormalRange/All',
        getNormalRange: 'VitalSignNormalRange/ById',

    };

    admissionSetup = {
        url: 'AdmissionSetup',
    };

    specialityWidget = {
        url: 'SpecialityWidget',
        add: 'SpecialityWidget/add',
        getBySpeciality: 'SpecialityWidget/BySpeciality',
        getByStaffId: 'SpecialityWidget/ByStaffId',
        getAll: 'SpecialityWidget/All',
    };

    appointmentSetup = {
        url: 'AppointmentSetup',
    };

    sickLeave = {
        url: 'SickLeave',
        bySearch: 'SickLeave/SickLeaveReportBySearch'
    };

    updateOutpatientSetup = {
        url: 'UpdateOutpatientSetup',
    };

    preRequisiteMedicalEntrySetup = {
        url: 'PreRequisiteMedicalEntrySetup',
    }

    claimAttachment = {
        url: 'ClaimAttachment',
        retry: 'ClaimAttachment/ManualRetry',
        generate: 'ClaimAttachment/Generate'
    }
}
