import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryModelApiConfiguration } from '../apiConfiguration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { environment } from 'environments/environment';

@Injectable()
export class CalculateAgeService extends ServiceBase {

    private actionUrl: string;
    private url: string;


    constructor(private _http: HttpClient, private _configuration: QueryModelApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.calculatePatientAge.url;
    }

    calculateAge = (birthDate: any): Observable<any> => {
        const date = birthDate instanceof Date ? birthDate.toDateString() : new Date(birthDate).toDateString();
        const validDate = isNaN(new Date(date).getTime()) ? '' : date;
        return this._http.get<any>(`${this.url}?birthDate=${validDate}`);
    }
}