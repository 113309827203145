export class CategoryInfoDTO {
    Id: number;
    Title: string;
    ForeignTitle: string;
    CategoryIds: number[];
    constructor() {

    }

}
