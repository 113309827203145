import { SelectItem } from 'primeng/api';
import { PatientTypes } from '../enums/patient-types';

export class PatientTypesLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'In Patient', value: PatientTypes.inPatient },
            { label: 'Out Patient', value: PatientTypes.outPatient }];
    }

    // Get Patient Types As Lookup with out all labe (using both) 
    static getLookupWithBoth(): SelectItem[] {
        return [
            { label: 'In Patient', value: PatientTypes.inPatient },
            { label: 'Out Patient', value: PatientTypes.outPatient },
            { label: 'Both', value: PatientTypes.Both },
        ];
    }

    // Get Patient Types As Lookup with out both label (for reports) 
    static getLookupForReport(): SelectItem[] {
        return [
            { label: 'In Patient', value: PatientTypes.inPatient },
            { label: 'Out Patient', value: PatientTypes.outPatient },
            { label: 'Both', value: null },
        ];
    }


    static getInPatientLookup(): SelectItem[] {
        return [
            { label: 'In Patient', value: PatientTypes.inPatient },
        ];
    }

    static getOutPatientLookup(): SelectItem[] {
        return [
            { label: 'Out Patient', value: PatientTypes.outPatient },
        ];
    }

    static getInOutLookup(): SelectItem[] {
        return [
            { label: 'Select Patient Type', value: null },
            { label: 'In Patient', value: PatientTypes.inPatient },
            { label: 'Out Patient', value: PatientTypes.outPatient },
            { label: 'ER', value: PatientTypes.ER }
        ];
    }

    static getInOutOnlly(): SelectItem[] {
        return [
            { label: 'In Patient', value: PatientTypes.inPatient },
            { label: 'Out Patient', value: PatientTypes.outPatient },
        ];
    }

    static getWithBoth(): SelectItem[] {
        return [
            { label: 'Both', value: null },
            { label: 'In Patient', value: PatientTypes.inPatient },
            { label: 'Out Patient', value: PatientTypes.outPatient },
        ];
    }
}
