import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { SelectItem, } from 'primeng/api';

import { BaseComponent } from 'app/shared/infrastructure';

// Services
import { LabSectionService } from 'app/shared/services/lab/labSection';
import { LookUpService } from 'app/shared/services/core/lookup';
import { CodeService, PayerService } from 'app/shared/services';

// Models
import { LabOrderSpecimenTestSearchModel } from 'app/shared/models/queryModel/lab-order-specimen-test-search';
import { Section } from 'app/shared/models/lab/section';
import { Control } from 'app/shared/models/core/control';
import { TestResultType } from 'app/shared/models/lab/enums/test-result-type';
import { TestResultTypesLookup } from 'app/shared/models/lab/lookups/test-result-types-lookup';
import { ExistResultStatusLookup } from 'app/shared/models/lab/lookups/exist-result-status-lookup';
import { PatientTypesLookup } from 'app/shared/models/orders-and-billing';
import { catchError, forkJoin, of, switchMap } from 'rxjs';

@Component({
    selector: 'advance-search-lab-order-specimen-test-cmp',
    styleUrls: ['./advanceSearchLabOrderSpecimenTest.scss'],
    templateUrl: './advanceSearchLabOrderSpecimenTest.html',
})

export class AdvanceSearchLabOrderSpecimenTest extends BaseComponent implements OnInit {
    @Output() onSearch = new EventEmitter<any>();

    labOrderSpecimenTestSearchModel: LabOrderSpecimenTestSearchModel = new LabOrderSpecimenTestSearchModel();

    _refreshInformation: LabOrderSpecimenTestSearchModel;
    @Input() set refreshInformation(value: LabOrderSpecimenTestSearchModel) {
        this._refreshInformation = value;
        if (this._refreshInformation) {
            this.labOrderSpecimenTestSearchModel = new LabOrderSpecimenTestSearchModel();
            this.labOrderSpecimenTestSearchModel = value;
        }
    }

    labSections: SelectItem[];
    doctorStaff: SelectItem[];
    growthRates: SelectItem[];
    payers: SelectItem[] = [];
    testResultTypes: SelectItem[] = TestResultTypesLookup.getLookup();;
    existResultStatus: SelectItem[] = ExistResultStatusLookup.getLookup();;
    testResultTypeEnum: typeof TestResultType = TestResultType;
    patientTypes: SelectItem[] = PatientTypesLookup.getWithBoth();

    ngOnInit() {
        this.get();
    }

    constructor(private labSectionService: LabSectionService,
        private lookUpService: LookUpService,
        private codeService: CodeService,
        private _payerService: PayerService) {
        super();
    }

    get() {
        this.labSections = [];
        this.doctorStaff = [];
        this.growthRates = [];
        this.payers = [];

        const forkJoinObs = {
            labSections: this.labSectionService.getAll().pipe(catchError(() => of([]))),
            doctorStaff: this.lookUpService.getDoctorStaff().pipe(catchError(() => of([]))),
            growthRates: this.codeService.getActiveCodesByGroupKey('GROWTH_RATE').pipe(catchError(() => of([]))),
            payers: this._payerService.getActivePayers().pipe(catchError(() => of([]))),

        }
        of(forkJoin(forkJoinObs)).pipe(
            switchMap(value => value)).subscribe({
                next: (response: any) => {
                    response = this.camelizeKeys(response);
                    this.labSections = response.labSections.map(m => ({ value: m.id, label: m.title })) || [];
                    this.doctorStaff = response.doctorStaff.map(m => ({ value: m.id, label: m.title })) || [];
                    this.growthRates = response.growthRates.map(m => ({ value: m.id, label: m.title })) || [];
                    this.growthRates.unshift({ label: 'All', value: null });
                    this.payers = response.payers.map(m => ({ value: m.payerId, label: m.title })) || [];
                    this.payers.unshift({ label: 'All', value: null });
                },
                error: (e) => {
                    this.showError(e);
                },
            });
    }

    sendSearchCriteria() {
        this.onSearch.emit(this.labOrderSpecimenTestSearchModel);
    }

    resetCultureDdl() {
        if (this.labOrderSpecimenTestSearchModel &&
            this.labOrderSpecimenTestSearchModel.ResultTypeId &&
            this.labOrderSpecimenTestSearchModel.ResultTypeId != TestResultType.Culture) {
            this.labOrderSpecimenTestSearchModel.CultureResultId = null;
        }
    }

    setResultTypeCulture() {
        if (this.labOrderSpecimenTestSearchModel &&
            this.labOrderSpecimenTestSearchModel.CultureResultId) {
            this.labOrderSpecimenTestSearchModel.ResultTypeId = TestResultType.Culture;
        }
    }
}
