import { ConsultationRequestType } from '../enums';

export class ConsultationRequestTypeLookup {
    static get(): any[] {
        return [
            { label: 'Transfer', value: ConsultationRequestType.Transfer },
            { label: 'Referral', value: ConsultationRequestType.Referral },
            { label: 'Consultation', value: ConsultationRequestType.Consultation },
        ];
    }
}
