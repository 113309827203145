import { SelectItem } from 'primeng/api';
import { EApprovalType } from '../enums/e-approval-type';

export class EApprovalTypesLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Approval', value: EApprovalType.Approval },
            { label: 'Admission Approval', value: EApprovalType.AdmissionApproval }];
    }
}
