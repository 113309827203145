export class ClaimAttachmentModel {
    patientCode: any;
    claimId: number;
    patientTypeId: number;
    doctorId: number;
    sectionId: number;
    statusId: number;
    payerId: number;
    coverageContractId: number;
    coverageClassId: number;
    fromDate: Date = new Date(new Date().setDate(new Date().getDate() - 30));
    toDate: Date = new Date();
    pageIndex: number;
    rowNumber: number;
}