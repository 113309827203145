import { SelectItem } from 'primeng/api';
import { CoverageClassType } from '../enums/coverage-class-type';

export class CoverageClassTypeLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'Group', value: CoverageClassType.Group },
            { label: 'Sub Group', value: CoverageClassType.SubGroup },
            { label: 'Class', value: CoverageClassType.Class },
            { label: 'Sub Class', value: CoverageClassType.SubClass },
            { label: 'Plan', value: CoverageClassType.Plan },
            { label: 'Sub Plan', value: CoverageClassType.SubPlan },
            { label: 'Sequence', value: CoverageClassType.Sequence },
            { label: 'Rx Bin', value: CoverageClassType.RxBin },
            { label: 'Rx Pcn', value: CoverageClassType.RxPcn },
            { label: 'Rx Id', value: CoverageClassType.RxId },
            { label: 'Rx Group', value: CoverageClassType.RxGroup },
        ];
    }
}
