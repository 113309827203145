import { DoctorsSpecialityStatus } from '../enums/doctor-speciality-status';
import { SelectItem } from 'primeng/api';

export class DoctorsSpecialityStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Active', value: true },
            { label: 'InActive', value: false }
        ];
    }
}
