import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { OrderApiConfiguration } from '../../order-and-billing/order-api-configuration';
import { MainFundDetailsViewModel } from 'app/shared/models/orders-and-billing/main-fund-details-view-model';
import { TransferRequest } from 'app/shared/models/orders-and-billing/transfer-request.model';
import { SapStatementTransaction } from 'app/shared/models/orders-and-billing/sap-statement-transaction.model';
import { SettlementViewModel } from 'app/shared/models/orders-and-billing/settlement-view-model';
import { FilterStatementTransaction } from 'app/shared/models/orders-and-billing/filter-statement-transaction';
import { StatementCancelationInfo } from 'app/shared/models/orders-and-billing/statement-cancelation-info';
import { ServiceBase } from 'app/shared/infrastructure';
import { forkJoin, map } from 'rxjs';
import { environment } from 'environments/environment';
@Injectable()
export class StatementTransactionService extends ServiceBase {

    private actionUrl: string;
    private url: string;
    private downPayment: string;
    private payment: string;
    private refund: string;
    private _getStatementTransactions: string;
    private getFundStatementTransactionsUrl: string;
    private _downPaymentCancelation: string;
    private _discountCancelation: string;
    private _invoiceCancelation: string;
    private _mainFundTransactionDetails: string;
    private _mainFundReceiveTransaction: string;
    private getSapFailedIncomingPaymentsUrl: string;
    private retrySapFailedIncomingPaymentsUrl: string;
    private getSapFailedOutgoingPaymentsUrl: string;
    private retrySapFailedOutgoingPaymentsUrl: string;
    private retrySapFailedCancelDownPaymentsUrl: string;
    private retrySapFailedRefundPaymentsUrl: string;
    private settlePatientBalanceUrl: string;
    private _searchTransactionStatements: string;
    private _exportTransactionStatements: string;
    private _isFoundPendingRefundTransaction: string;
    private getApproveRequest: string;
    private approveRefund: string;
    private _isFoundPendingDiscountTransaction: string;
    private _patientDeposit: string;
    private _patientBalanceTransaction: string;
    private _depositList: string;
    private _calculateRemainingAmount: string;
    private _cancelFinanceSettlement: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.url = this.actionUrl + _configuration.statementTransaction.url;
        this.downPayment = this.actionUrl + _configuration.statementTransaction.downPayment;
        this.payment = this.actionUrl + _configuration.statementTransaction.payment;
        this.refund = this.actionUrl + _configuration.statementTransaction.refund;
        this._getStatementTransactions = this.actionUrl + _configuration.statementTransaction.statementTransactions;
        this.getFundStatementTransactionsUrl = this.actionUrl + _configuration.statementTransaction.getFundStatementTransactionsUrl;
        this._downPaymentCancelation = this.actionUrl + _configuration.statementTransaction.downPaymentCancelation;
        this._discountCancelation = this.actionUrl + _configuration.statementTransaction.discountCancelation;
        this._invoiceCancelation = this.actionUrl + _configuration.statementTransaction.invoiceCancelation;
        this._mainFundTransactionDetails = this.actionUrl + _configuration.statementTransaction.mainFundTransactionDetails;
        this._mainFundReceiveTransaction = this.actionUrl + _configuration.statementTransaction.mainFundReceiveTransaction;
        this.getSapFailedIncomingPaymentsUrl = this.actionUrl + _configuration.statementTransaction.getSapFailedIncomingPaymentsUrl;
        this.retrySapFailedIncomingPaymentsUrl = this.actionUrl + _configuration.statementTransaction.retrySapFailedIncomingPaymentsUrl;
        this.getSapFailedOutgoingPaymentsUrl = this.actionUrl + _configuration.statementTransaction.getSapFailedOutgoingPaymentsUrl;
        this.retrySapFailedOutgoingPaymentsUrl = this.actionUrl + _configuration.statementTransaction.retrySapFailedOutgoingPaymentsUrl;
        this.retrySapFailedCancelDownPaymentsUrl = this.actionUrl + _configuration.statementTransaction.retrySapFailedCancelDownPaymentsUrl;
        this.retrySapFailedRefundPaymentsUrl = this.actionUrl + _configuration.statementTransaction.retrySapFailedRefundPaymentsUrl;
        this.settlePatientBalanceUrl = this.actionUrl + _configuration.statementTransaction.settlePatientBalanceUrl;
        this._searchTransactionStatements = this.actionUrl + _configuration.statementTransaction.searchTransactionStatements;
        this._exportTransactionStatements = this.actionUrl + _configuration.statementTransaction.exportTransactionStatements;
        this._isFoundPendingRefundTransaction = this.actionUrl + _configuration.statementTransaction.pendingFundStatementTransactions;
        this.getApproveRequest = this.actionUrl + _configuration.statementTransaction.getApproveRequest;
        this.approveRefund = this.actionUrl + _configuration.statementTransaction.approveRefund;
        this._isFoundPendingDiscountTransaction = this.actionUrl + _configuration.statementTransaction.pendingDiscountTransaction;
        this._patientDeposit = this.actionUrl + _configuration.statementTransaction.patientDeposit;
        this._patientBalanceTransaction = this.actionUrl + _configuration.statementTransaction.patientBalanceTransaction;
        this._depositList = this.actionUrl + _configuration.statementTransaction.depositList
        this._calculateRemainingAmount = this.actionUrl + _configuration.statementTransaction.calculateRemainingAmount;
        this._cancelFinanceSettlement = this.actionUrl + _configuration.statementTransaction.cancelFinanceSettlement;
    }

    cancelInvoice = (statementCancelationInfo: StatementCancelationInfo): Observable<any> => {
        return this._http.put<any>(this._invoiceCancelation, JSON.stringify(statementCancelationInfo))
    }

    cancelDiscount = (statementCancelationInfo: StatementCancelationInfo): Observable<any> => {
        return this._http.post<any>(this._discountCancelation, JSON.stringify(statementCancelationInfo))
    }

    mainFundReceiveTransaction = (fundId, statementTransactions, fromFundUserId, fromFundId, actualAmount, calculatedAmount): Observable<any> => {
        return this._http.post<any>(this._mainFundReceiveTransaction + '?fundId=' + fundId + '&&fromFundUserId=' + fromFundUserId + '&&fromFundId=' + fromFundId + '&&actualAmount=' + actualAmount + '&&calculatedAmount=' + calculatedAmount, statementTransactions)
    }

    cancelDownPayment = (statementCancelationInfo: StatementCancelationInfo): Observable<any> => {
        return this._http.post<any>(this._downPaymentCancelation, JSON.stringify(statementCancelationInfo))
    }

    getStatementTransactions = (patientStatementId): Observable<any> => {
        return this._http.get<any>(this._getStatementTransactions + '?patientStatementId=' + patientStatementId)
    }

    getFundStatementTransactions = (fundId: number, userId: number, facilityId: number): Observable<TransferRequest> => {
        const url = `${this.getFundStatementTransactionsUrl}?fundId=${fundId}&userId=${userId}&facilityId=${facilityId}`;
        return this._http.get<any>(url)
    }

    public postDownPayment = (patientStatementId, data): Observable<any> => {
        return this._http.post<any>(this.downPayment + '?patientStatementId=' + patientStatementId, data);
    }

    public postPayment = (patientStatementId, data): Observable<any> => {
        return this._http.post<any>(this.payment + '?patientStatementId=' + patientStatementId, data);
    }

    public postRefund = (patientStatementId, data): Observable<any> => {
        return this._http.post<any>(this.refund + '?patientStatementId=' + patientStatementId, data);
    }

    getMainFundTransactionDetails = (fundUserId, fundId, fromDate, toDate): Observable<MainFundDetailsViewModel[]> => {
        return this._http.get<any>(this._mainFundTransactionDetails + '?fundUserId=' + fundUserId + '&&fundId=' + fundId +
            '&&fromDate=' + fromDate.toLocaleDateString() + '&&toDate=' + toDate.toLocaleDateString());
    }
    cancelFinanceSettlement(statementId, patientType, orderId, orderSourceId) {
        return this._http.get(this._cancelFinanceSettlement + '?statementId=' + statementId + '&&patientType=' + patientType + '&&orderId=' + orderId + '&&orderSourceId=' + orderSourceId);
    }
    getSapFailedIncomingPayments = (transactionDateFrom: Date, transactionDateTo: Date): Observable<SapStatementTransaction[]> => {
        const url = `${this.getSapFailedIncomingPaymentsUrl}?transactionDateFrom=${transactionDateFrom.toISOString()}&&transactionDateTo=${transactionDateTo.toISOString()}`;
        return this._http.get<any>(url);
    }

    retrySapFailedIncomingPayments = (incomingPaymentsIds: number[]) => {
        return this._http.post<any>(this.retrySapFailedIncomingPaymentsUrl, JSON.stringify(incomingPaymentsIds));
    }

    getSapFailedOutgoingPayments = (transactionDateFrom: Date, transactionDateTo: Date): Observable<SapStatementTransaction[]> => {
        const url = `${this.getSapFailedOutgoingPaymentsUrl}?transactionDateFrom=${transactionDateFrom.toISOString()}&&transactionDateTo=${transactionDateTo.toISOString()}`;
        return this._http.get<any>(url);
    }

    retrySapFailedOutgoingPayments(failedRefundStatementTransactionsIds: number[],
        failedCancelDownPaymentsIds: number[]) {

        return forkJoin(this.retrySapFailedCancelDownPayments(failedCancelDownPaymentsIds),
            this.retrySapFailedRefundPayments(failedRefundStatementTransactionsIds));
    }

    retrySapFailedCancelDownPayments(statementTransactionsIds: number[]) {
        return this._http.post<any>(this.retrySapFailedCancelDownPaymentsUrl, JSON.stringify(statementTransactionsIds));
    }

    retrySapFailedRefundPayments(statementTransactionsIds: number[]) {
        return this._http.post<any>(this.retrySapFailedRefundPaymentsUrl, JSON.stringify(statementTransactionsIds));
    }

    settlePatientBalance(patientStatmentId: number, fundId: number, notes: string) {
        let settlementViewModel = new SettlementViewModel();
        settlementViewModel.patientStatementId = patientStatmentId;
        settlementViewModel.fundId = fundId;
        settlementViewModel.notes = notes;

        return this._http.post<any>(this.settlePatientBalanceUrl, JSON.stringify(settlementViewModel));
    }

    searchTransactionStatements = (_filter: FilterStatementTransaction): Observable<any> => {
        let queryString: string = '';

        queryString += '?Mrn=';
        queryString += _filter.Mrn;

        queryString += '&PatientName=';
        queryString += _filter.PatientName;

        queryString += '&TransactionDateFrom=';
        queryString += _filter.TransactionDateFrom ? _filter.TransactionDateFrom.toISOString() : null;

        queryString += '&TransactionDateTo=';
        queryString += _filter.TransactionDateTo ? _filter.TransactionDateTo.toISOString() : null;

        queryString += '&PaymentCodeId=';
        queryString += _filter.PaymentCodeId ? _filter.PaymentCodeId : null;

        queryString += '&TransactionById=';
        queryString += _filter.TransactionById ? _filter.TransactionById : null;

        queryString += '&page=';
        queryString += _filter.Page ? _filter.Page : 0;

        return this._http.get<any>(this._searchTransactionStatements + queryString)
    }

    exportTransactionStatements = (_filter: FilterStatementTransaction): Observable<any> => {

        let queryString: string = '';

        queryString += '?Mrn=';
        queryString += _filter.Mrn;

        queryString += '&PatientName=';
        queryString += _filter.PatientName;

        queryString += '&TransactionDateFrom=';
        // this guided by ala'a abu khalil
        queryString += _filter.TransactionDateFrom !== undefined ? _filter.TransactionDateFrom.toISOString() : _filter.TransactionDateFrom;

        queryString += '&TransactionDateTo=';
        // this guided by ala'a abu khalil
        queryString += _filter.TransactionDateTo !== undefined ? _filter.TransactionDateTo.toISOString() : _filter.TransactionDateTo;

        queryString += '&PaymentCodeId=';
        queryString += _filter.PaymentCodeId ? _filter.PaymentCodeId : null;

        queryString += '&TransactionById=';
        queryString += _filter.TransactionById ? _filter.TransactionById : null;

        queryString += '&page=';
        queryString += _filter.Page ? _filter.Page : 1;

        return this._http.get<any>(this._exportTransactionStatements + queryString,
            { responseType: 'blob' as 'json', observe: 'response' }).pipe(
                map((response: HttpResponse<Blob>) => {
                    if (response != null) {
                        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        var blob = new Blob(['\ufeff', (<any>response).body], { type: contentType });
                        return blob;
                    }

                    return response;
                }));
    }

    isFoundPendingRefundTransaction = (patientId, encounterId, patientType): Observable<MainFundDetailsViewModel[]> => {
        return this._http.get<any>(this._isFoundPendingRefundTransaction + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType);
    }

    getApproveRequests = (): Observable<any> => {
        return this._http.get<any>(this.getApproveRequest)
    }

    approveRefundTransaction = (statementTransactionId): Observable<any> => {
        return this._http.put<any>(`${this.approveRefund}?statementTransactionId=${statementTransactionId}`, statementTransactionId)
    }

    isFoundPendingDiscountTransaction = (patientId, encounterId, patientType): Observable<MainFundDetailsViewModel[]> => {
        return this._http.get<any>(this._isFoundPendingDiscountTransaction + '?patientId=' + patientId + '&&encounterId=' + encounterId + '&&patientType=' + patientType);
    }

    patientDeposit = (data): Observable<any> => {
        return this._http.post<any>(this._patientDeposit, data)
    }

    addPatientBalanceTransaction = (data): Observable<any> => {
        return this._http.post<any>(this._patientBalanceTransaction, data)
    }

    getDepositList = (patientId): Observable<any[]> => {
        return this._http.get<any>(this._depositList + '?patientId=' + patientId);
    }

    calculateRemainingAmount = (patientId, encounterId = null, patientTypeId = null): Observable<any[]> => {
        let url = `${this._calculateRemainingAmount}?patientId=${patientId}&encounterId=${encounterId}&patientTypeId=${patientTypeId}`;
        return this._http.get<any>(url);
    }

    getDepositEncounterBySearch = (patientId, downPaymentId = null, fromDate = null, toDate = null): Observable<any[]> => {
        let url = `${environment.queryModel}DepositEncounter/GetDepositEncounterBySearch?PatientId=${patientId}&DownPaymentId=${downPaymentId || ''}&FromEncounterDate=${fromDate?.toISOString() || ''}&ToEncounterDate=${toDate?.toISOString() || ''}`;
        return this._http.get<any>(url);
    }

    depositRefund = (data): Observable<any> => {
        return this._http.post<any>(`${environment.order}StatementTransaction/DepositRefund`, data)
    }

    getEncounterDetails = (patientId, encounterId, patientStatementId): Observable<any[]> => {
        let url = `${environment.order}StatementTransaction/DepositEncounterDetails?patientId=${patientId}&encounterId=${encounterId}&patientStatementId=${patientStatementId}`;
        return this._http.get<any>(url);
    }

    rejectStatementTransaction = (id): Observable<any> => {
        return this._http.put<any>(`${this.url}/RejectStatementTransaction?id=${id}`, null)
    }

    getAllDepositEncounterDetails = (patientId): Observable<any[]> => {
        let url = `${environment.order}StatementTransaction/PatientDepositDetails?patientId=${patientId}`;
        return this._http.get<any>(url);
    }

    getRemainingAmountList = (patientId: any, patientType: any = null, encounterId: any = null): Observable<any[]> => {
        let url = `${environment.order}StatementTransaction/RemainingAmountList?patientId=${patientId}&patientType=${patientType}&encounterId=${encounterId}`;
        return this._http.get<any>(url);
    }

    postEligibilityPayment = (patientStatementId, data): Observable<any> => {
        return this._http.post<any>(`${environment.order}StatementTransaction/EligibilityPayment?patientStatementId=${patientStatementId}`, data);
    }

    updateDepositEncounter = (statementTransactionId, patientType, sectionIds): Observable<any> => {
        return this._http.post<any>(`${environment.order}StatementTransaction/UpdateDeposit?statementTransactionId=${statementTransactionId}&patientType=${patientType}`, sectionIds)
    }
}
