import { DiscountPer } from "../enum/discount-per";

export class CashierDiscount {
    StaffId: number;
    FullName : string = '';
    OtherFullName: string = '';
    InpatientDiscountFrom: number = 0;
    InpatientDiscountTo: number = 0;
    OutpatientDiscountFrom: number = 0;
    OutpatientDiscountTo: number = 0;
    IsActive: boolean = false;
    DiscountPer: number = 0;
}