import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs-compat/Observable';
import { forkJoin, map } from 'rxjs';
import { OrderApiConfiguration } from '../order-and-billing/order-api-configuration';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';
import { OrderBasicInfo } from 'app/shared/models/orders-and-billing/order-basic-info';
import { OrderActionViewModel } from 'app/shared/models/orders-and-billing/order-action-view-model';
import { StoreBalance } from '../../models/orders-and-billing/store-balance';
import { Item } from 'app/shared/models/orders-and-billing/item';
import { environment } from 'environments/environment';

@Injectable()
export class OrderService extends ServiceBase {

    private actionUrl: string;
    private getUrl: string;
    private postUrl: string;
    private getByStaff: string;
    private editOrderDoctorUrl: string;
    private _checkBalance: string;
    private _checkBatchBalance: string;
    private _orderUrl: string;
    private _orderAction: string;
    private _latestOrderDateUrl: string;
    private _approvePendingAreasUrl: string;
    private _postConsultatioService: string;
    private _rollBackPendingAreasUrl: string;

    constructor(private _http: HttpClient, private _configuration: OrderApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this.getUrl = this.actionUrl + _configuration.order.url;
        this.postUrl = this.actionUrl + _configuration.order.url;
        this.getByStaff = `${this.actionUrl}/StaffCategory/ByStaff`;
        this._checkBalance = this.actionUrl + _configuration.order.checkBalance;
        this._checkBatchBalance = this.actionUrl + _configuration.order.checkBatchBalance;
        this.editOrderDoctorUrl = this.actionUrl + this._configuration.order.editOrderDoctor;
        this._orderAction = _configuration.server;
        this._orderUrl = this._orderAction + _configuration.item.LabParents;
        this._latestOrderDateUrl = this.actionUrl + _configuration.order.latestOrderDate;
        this._approvePendingAreasUrl = this.actionUrl + _configuration.order.approvePendingAreas;
        this._postConsultatioService = this.actionUrl + _configuration.order.postConsultationService;
        this._rollBackPendingAreasUrl = this.actionUrl + _configuration.order.rollBackPendingAreas;
    }

    get = (): Observable<any> => {
        return null;
    }

    getById = (id: number): Observable<OrderBasicInfo> => {
        return this._http.get<any>(`${this.getUrl}/BasicInfo?id=${id}`);
    }

    isItemIncludeRequiredInformation = (itemId: number, patientId: number): Observable<boolean> => {
        return this._http.get<any>(`${this.getUrl}/IsItemIncludeRequiredInformation?itemId=${itemId} &patientId=${patientId}`);;
    }

    isAllowedTestReorder = (itemId: number, patientId: number, orderDate: string): Observable<boolean> => {
        return this._http.get<any>(`${this.getUrl}/AllowedTestReorder?itemId=${itemId} &patientId=${patientId} &orderDate=${orderDate}`);
    }

    hasItemPACSCode = (itemId: number): Observable<boolean> => {
        return this._http.get<any>(`${this.getUrl}/ItemPACSCode?itemId=${itemId}`);
    }

    itemPACSCodeValue = (itemId: number): Observable<string> => {
        return this._http.get<any>(`${this.getUrl}/ItemPACSCodeValue?itemId=${itemId}`);
    }

    validateNameLanguage = (patientName: string, doctorName: string): Observable<boolean> => {
        return this._http.get<any>(`${this.getUrl}/NamesCharactersValid?patientName=${patientName}&doctorName=${doctorName}`);
    }

    hasTestTimeSpan = (itemId: number): Observable<boolean> => {
        return this._http.get<any>(`${this.getUrl}/TestTimeSpan?itemId=${itemId}`);
    }

    getItemBalance = (itemCode, whereHouseCode): Observable<any> => {
        return this._http.get<any>(`${this.getUrl}/Item/Balance?itemCode=${itemCode}&whereHouseCode=${whereHouseCode}`);
    }

    conversionToBaseQty(itemId: number, requiredUnitId: number, quantity: number) {
        return this._http.get<any>(`${this.getUrl}/UnitConversion?itemId=${itemId}&requiredUnitId=${requiredUnitId}&quantity=${quantity}`);
    }

    checkBalanceStatus = (): Observable<any> => {
        return this._http.get<any>(`${this.getUrl}/PreventOrderStatus`);
    }

    getAllowedItemCategoriesAndCheckBalanceSettings = (): Observable<any> => {

        let url1 = this._http.get<any>(`${this.getUrl}/PreventOrderStatus`).pipe(map(res => res));
        let url2 = this._http.get<any>(this.getByStaff).pipe(map(res => res));

        return forkJoin([url1, url2]);
    }

    editOrderDoctor = (orderActionViewModel: OrderActionViewModel) => {
        return this._http.put<any>(this.editOrderDoctorUrl, orderActionViewModel);
    }

    checkBalance = (itemCode: string, warehouseCodes: string[]): Observable<StoreBalance[]> => {
        return this._http.get<any>(`${this._checkBalance}?itemCode=${itemCode}&whereHouseCodes=${warehouseCodes.join(',')}`);
    }

    getItemCode = (code: string): Observable<Item[]> => {
        return this._http.get<any>(this._orderUrl + '?Code=' + code);
    }

    // getSpecimenBarCodeReportURL = (): Observable<string> => {
    //     return this._specimenBarCodeReportURL;
    // }
    getLatestOrderDate = (patientId: number, encounterId: number): Observable<Date> => {
        return this._http.get<any>(this._latestOrderDateUrl + '?patientId=' + patientId + "&encounterId=" + encounterId);
    }

    approvePendingAreas = (orderActionIds: any[]): Observable<any> => {
        return this._http.post<any>(`${this._approvePendingAreasUrl}`, orderActionIds);
    }

    getItemBatchBalance = (itemCode, whereHouseCode): Observable<any> => {
        return this._http.get<any>(`${this._checkBatchBalance}?itemCode=${itemCode}&whereHouseCode=${whereHouseCode}`);
    }

    postConsultationService = (warehouseCode: string, sectionName: string, data: any): Observable<any> => {
        return this._http.post<any>(`${this._postConsultatioService}?warehouseCode=${warehouseCode}&sectionName=${sectionName}`, JSON.stringify(data));
    }

    enableKHCC = (): Observable<any> => {
        return this._http.get<any>(`${environment.order}/order/EnableKHCC`);
    }

    isInforStockBalance = (): Observable<any> => {
        return this._http.get<any>(`${environment.order}/order/IsInforStockBalance`);
    }

    cancelConsultationOrder = (patientId: number, encounterId: number, itemId: number): Observable<any> => {
        return this._http.put<any>(`${this.getUrl}/CancelConsultationOrder?patientId=${patientId}&encounterId=${encounterId}`, JSON.stringify(itemId));
    }

    rollBackPendingAreas = (orderActionIds: any[]): Observable<any> => {
        return this._http.post<any>(`${this._rollBackPendingAreasUrl}`, orderActionIds);
    }
}
