import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat/Observable'
import { CoreApiConfiguration } from '../coreApiConfiguration';
import { SessionVisit } from 'app/shared/models/core/session-visit';
import { ServiceBase } from 'app/shared/infrastructure/serviceBase.component';

@Injectable()
export class SessionVisitService extends ServiceBase {
    private actionUrl: string;
    private _url: string;
    private _activeByPatientUrl: string;
    private _byPatientUrl:string;
    private _bySearchUrl: string;
    private _openPatientSessionUrl: string;
    private _closePatientSessionUrl: string;
    private _sessionEncounters:String;
    private _infoByPatient:String;

    constructor(private _http: HttpClient, private _configuration: CoreApiConfiguration) {
        super();
        this.actionUrl = _configuration.server;
        this._url = this.actionUrl + _configuration.sessionVisit.url;
        this._activeByPatientUrl = this.actionUrl + _configuration.sessionVisit.activeByPatient;
        this._byPatientUrl=this.actionUrl + _configuration.sessionVisit.byPatient;
        this._bySearchUrl = this.actionUrl + _configuration.sessionVisit.bySearch;
        this._openPatientSessionUrl = this.actionUrl + _configuration.sessionVisit.openPatientSession;
        this._closePatientSessionUrl = this.actionUrl + _configuration.sessionVisit.closePatientSession;
        this._sessionEncounters = this.actionUrl + _configuration.sessionVisit.sessionEncounters;
        this._infoByPatient = this.actionUrl + _configuration.sessionVisit.infoByPatient;
    }

    getActiveByPatient = (patientId: number, visitDate: Date): Observable<SessionVisit[]> => {
        var visitDateValue = visitDate ? visitDate.toISOString() : null;
        return this._http.get<any>(this._activeByPatientUrl + '?patientId=' + patientId + '&&visitDate=' + visitDateValue);
    }

    getByPatient = (patientId: number): Observable<SessionVisit[]> => {
        return this._http.get<any>(this._byPatientUrl + '?patientId=' + patientId );
    }

    getBySearch = (sessionId: number, patientCode: string, statusId: number, page: number, rows: number): Observable<SessionVisit[]> => {
        var sessionIdValue = sessionId ? sessionId : null;
        var patientCodeValue = patientCode ? patientCode : '';
        var statusIdValue = statusId ? statusId : null;
        return this._http.get<any>(this._bySearchUrl + '?sessionId=' + sessionIdValue + '&patientCode=' + patientCodeValue+ '&statusId=' + statusIdValue+ '&page=' + page+ '&rows=' + rows);
    }

    openPatientSession = (sessionId:number): Observable<any> => {
        return this._http.put<any>(this._openPatientSessionUrl + '?sessionId=' + sessionId, null);
    }

    closePatientSession = (sessionId:number): Observable<any> => {
        return this._http.put<any>(this._closePatientSessionUrl + '?sessionId=' + sessionId, null);
    }

    getSessionEncounters = (sessionId:number): Observable<any> => {
        return this._http.get<any>(this._sessionEncounters + '?sessionId=' + sessionId);
    }

    getInfoByPatient = (id:number): Observable<any> => {
        return this._http.get<any>(this._infoByPatient + '?id=' + id);
    }
}
