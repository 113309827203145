import { AdmissionReasonStatus } from '../enums/admission-reason-status';
import { SelectItem } from 'primeng/api';

export class AdmissionReasonStatusLookup {
    static getLookup(): SelectItem[] {
        return [
            { label: 'All', value: null },
            { label: 'Active', value: AdmissionReasonStatus.Active },
            { label: 'InActive', value: AdmissionReasonStatus.InActive }
        ];
    }
}
