import { OrganizationSection } from './organization-section';
import { Patient } from './patient.model';

export interface IPatienVisitMetaData {
    ConsultationTypeDescription: string;
}

export class PatienVisit {
    Id: number;
    SectionId: number;
    VisitNo: number;
    FacilityId: number;
    PatientId: number;
    DealingType: number;
    PartyId: number;
    RefernceId: number;
    DoctorId: number ;
    VisitDate: Date;
    Status: number;
    DischargeStatus: boolean;
    DischargeDate: Date;
    Remarks: string;
    UserId: number;
    PcName: string;
    EntryDate: Date;
    ComplainId: number;
    TelephoneType: number;
    TelephoneNo: string;
    IsErCase: boolean;
    AccidentId: number;
    IsPoliceCase: boolean;
    DischargeDoctor: number;
    DischargePartyId: number;
    DischargeReferanceId: number;
    ApptId: number;
    CrisisId: number;
    JudicialDischargeStatus: boolean;
    AttendNo: number;
    DischargeBy: number;
    DischargePc: string;
    CreatedBy: number;
    CreatedDate: Date;
    ModifiedBy: number;
    ModifiedDate: Date;
    ExternalDoctor: string;
    ExaminationReason: string;
    CancelReason: string;
    CancellationDate: Date;
    CancellationBy: number;
    ErAreaId: number;
    PoliceCaseNote: string;
    AccidentCaseNote: string;
    SessionNo: number;
    ConsultationTypeId: number;
    AppointmentDate: Date;
    IsAccident: boolean;
    SourceId: Number;
    Price: number;
    ConsultationServiceId?: number;
    ConsultationTypeDescription: string;
    SponsorName?: string;
    PayerTitle: string;
    PayerForeignTitle: string;
    InsuranceName: string;
    InsuranceClass: string;
    FormNumber: string;
    ServiceTypeId: number;
    CategoryId: number;
    OrganizationSection: OrganizationSection = new OrganizationSection();
    Patient: Patient = new Patient();
    BedId: number;
    PatientDiagnosis: number[] = [];
    SessionPeriod?: number;
    ArrivalId?: number;

    constructor(init?: Partial<PatienVisit>) {
        if (init) {
            for (const p in this) {
                this[p.toString()] = (p in init ? init : this)[p.toString()];
            }
        }
        return this;
    }
}
